import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, pipe, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { CampaignService } from './campaign.service';
import { Certificate } from '../models/magenta/certificate';
import { CertificateStatus } from '../models/magenta/certificate-status';
import { MagentaSource } from '../models/magenta/magenta-source';
import { Screening } from '../models/magenta/screening';
import { ScreeningResultEnum } from '../models/magenta/screening-result-enum';
import { Country } from '../models/magenta/country';
import { Destination } from '../models/magenta/destination';
import { Destination as DestinationConfigVersion } from '../models/destination';
import { TripLimit } from '../models/trip-limit';
import { TriggerQuestion } from '../models/magenta/trigger-question';
import { Scheme } from '../models/magenta/scheme';
import { SchemeAdditional } from '../models/scheme-additional';
import { SchemeCover } from '../models/magenta/scheme-cover';
import { SchemeCoverAdditional } from '../models/scheme-cover-additional';
import { File } from '../models/magenta/file';
import { GetSchemesRequest } from '../models/dto/scheme/get-scheme-request';
import { GetSchemesResponse } from '../models/dto/scheme/get-scheme-response';
import { CreateQuoteNumberRequest } from '../models/dto/save-policy/create-quote-number-request';
import { IncompleteQuoteRequest } from '../models/dto/save-policy/incomplete-quote-request';
import { IncompleteQuotePersonalDetailsRequest } from '../models/dto/save-policy/incomplete-quote-personal-details-request';
import { IncompleteQuoteClientRrtScreeningRequest } from '../models/dto/save-policy/incomplete-quote-client-rrt-screening-request';
import { ByExternalRefRequest } from '../models/dto/get-policy/by-external-ref-request';
import { ImportIdolCreatePolicyRequest } from '../models/dto/get-policy/import-idol-create-policy-request';
import { ByQuoteNumberAndEmailRequest } from '../models/dto/get-policy/by-quote-number-and-email-request';
import { ByQuoteNumberAndEmailResponse } from '../models/dto/get-policy/by-quote-number-and-email-response';
import { ByRecentlyPurchasedRequest } from '../models/dto/get-policy/by-recently-purchased-request';
import { ByRecentlyPurchasedResponse } from '../models/dto/get-policy/by-recently-purchased-response';
import { GenerateQuoteRequest } from '../models/dto/save-policy/generate-quote-request';
import { GeneratePolicyRequest } from '../models/dto/save-policy/generate-policy-request';
import { TriggerAnswersRequest } from '../models/dto/save-policy/trigger-answers-request';
import { EmailDocumentsRequest } from '../models/dto/document/email-documents-request';
import { EmailDocumentsResponse } from '../models/dto/document/email-documents-response';
import { GetDocumentsRequest } from '../models/dto/document/get-documents-request';
import moment, { Moment } from 'moment';
import { DATE_FORMATS } from '../models/date-formats-token';
import { DateFormats } from '../models/date-formats';
import { PolicyOption } from '../models/magenta/policy-option';


@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  private readonly _certificateSessionKey = 'pol_svc_certificate';
  private readonly _isSchemeUserSelectedSessionKey = 'pol_svc_scheme_user_selected';
  private readonly _hasAnsweredTriggerQuestionsSessionKey = 'pol_svc_has_answered_trigger_questions';
  private readonly _activeScreeningSessionKey = 'pol_svc_active_screening';
  private readonly _lastPurchasedPolicyReferenceSessionKey = 'pol_svc_last_purchased_pol_ref';
  private readonly _confEmailDocsFiredSessionKey = 'pol_svc_email_docs_fired';
  private _certificateSource: BehaviorSubject<Certificate>;
  private readonly _schemeGroupCountryCache = new Map<number, Country[]>();

  // Magenta aggregator source arrays.
  private readonly _magentaSourceIdol = [
    MagentaSource.Idol,
    MagentaSource.IdolCompareTheMarket,
    MagentaSource.IdolConfused,
    MagentaSource.IdolGoCompare,
    MagentaSource.IdolMoney,
    MagentaSource.IdolTescoGoCompare,
    MagentaSource.IdolUSwitch
  ];

  private readonly _magentaSourceCyti = [
    MagentaSource.CYTIDirect,
    MagentaSource.CYTIMSM,
    MagentaSource.CYTIMSMV1,
    MagentaSource.CYTIMTC,
    MagentaSource.CYTIPhoenix,
    MagentaSource.CYTITSM,
    MagentaSource.CYTTIS,
    MagentaSource.AequotechMTC
  ];

  private readonly _magentaSourceQuoteZone = [
    MagentaSource.QuoteZone
  ];
  
  public readonly certificate: Observable<Certificate>;

  constructor(private _appConfigService: AppConfigService,
              private _campaignService: CampaignService,
              private _http: HttpClient,
              @Inject(DATE_FORMATS) private _dateFormats: DateFormats) {

    this._certificateSource = new BehaviorSubject<Certificate>(null);
    this.certificate = this._certificateSource.asObservable();
    this.init();
    
  }

  private init(): void {

    // Check for certificate in session and load if present.
    if(sessionStorage.getItem(this._certificateSessionKey)) {

      const cert: Certificate = JSON.parse(sessionStorage.getItem(this._certificateSessionKey));
      this._campaignService.setActiveCampaign(cert.campaign, false);
      this.setCertificate(cert);

    }

  }

  /**
   * Returns an array of titles to be used with client title selection.
   */
  public getClientTitles(): string[] {
    return ["Mr", "Ms", "Miss", "Mrs", "Mx", "Dr", "Sir", "Lord", "Lady", "Professor", "Rev", "Master", "Sister"];
  }

  /**
   * Takes the client DOB as string and returns the age in years based on today's date.
   */
  public calculateClientAgeFromDob(clientDob: string): number {
    const dateToday = moment.utc(this._appConfigService.agent.dateToday, this._dateFormats.txRxFormat);
    const dob = moment.utc(clientDob, this._dateFormats.txRxFormat);
    return dateToday.diff(dob, 'years');
  }

  /**
   * Sets session var used to flag whether the selected scheme was user selected.
   * This is necessary due to a scheme needing to be selected in the background 
   * and allow Connect to perform medical screenings.
   */
  public setIsSchemeUserSelected(isSchemeUserSelected: boolean): void {

    sessionStorage.setItem(this._isSchemeUserSelectedSessionKey, isSchemeUserSelected.toString());

  }

  /**
   * Flag indicating whether scheme has been user selected, or dummy scheme set 
   * to enable Connect to work correctly.
   */
  public isSchemeUserSelected(): boolean {

    const isSelected = sessionStorage.getItem(this._isSchemeUserSelectedSessionKey);
    return isSelected === 'true';

  }

  /**
   * Sets session var used to flag indicating whether trigger questions have been answered for the given certificateId.
   */
  public setHasAnsweredTriggerQuestions(certificateId: number): void {

    sessionStorage.setItem(this._hasAnsweredTriggerQuestionsSessionKey, certificateId.toString());

  }

  /**
   * Flag indicating whether trigger questions have been answered for the given certificateId.
   */
  public hasAnsweredTriggerQuestions(certificateId: number): boolean {

    const sessionCertificateId = Number(sessionStorage.getItem(this._hasAnsweredTriggerQuestionsSessionKey));

    if(!isNaN(sessionCertificateId)) {
      return sessionCertificateId === certificateId;
    }

    return false;

  }

  /**
   * Takes the given certificate and returns a value indicating whether it contains any user selected policy
   * options - i.e. options that are not hidden in docs.
   */
  public hasUserSelectedPolicyOptions(certificate: Certificate): boolean {
    if(certificate?.policyOptions?.length > 0) {
      return certificate.policyOptions.find(po => !po.hideOnDocuments) !== undefined;
    }

    return false;
  }

  /**
   * Takes the given policy options array and returns the total value of all 
   * enabled default options. If supplied array null/empty returns 0.
   */
  public calculateDefaultPolicyOptionsTotal(policyOptions: PolicyOption[]): number {
    let total = 0;

    if(policyOptions?.length > 0) {
      policyOptions.forEach(opt => {
        if(opt.isEnabled && opt.isDefault) total += opt.gross;
      });
    }

    return total;
  }

  public setActiveScreening(certificateClientId: number): void {

    sessionStorage.setItem(this._activeScreeningSessionKey, certificateClientId.toString());

  }

  public getActiveScreening(): number {

    if(this.isActiveScreening() === false) {
      throw new Error('No active screening recorded in session.');
    }

    const certificateClientId = Number(sessionStorage.getItem(this._activeScreeningSessionKey));

    if(isNaN(certificateClientId)) {
      throw new Error('Active screening certificateClientId stored in session could not be parsed as number.');
    }

    return certificateClientId;

  }

  public isActiveScreening(): boolean {

    return sessionStorage.getItem(this._activeScreeningSessionKey) !== null;

  }

  public clearActiveScreening(): void {

    sessionStorage.removeItem(this._activeScreeningSessionKey);

  }

  /**
   * Sets the value of the last purchased policy reference to session.
   */
  public setLastPurchasedPolicyReference(policyReference: string): void {

    sessionStorage.setItem(this._lastPurchasedPolicyReferenceSessionKey, policyReference);

  }

  /**
   * Sets the value indicating policy reference for email docs call.
   */
  public setConfEmailDocsFired(policyReference: string): void {

    sessionStorage.setItem(this._confEmailDocsFiredSessionKey, policyReference);

  }

  /**
   * Returns a value indicating whether email docs has been fired for the given policy reference.
   */
  public hasConfEmailDocsFired(policyReference: string): boolean {

    return sessionStorage.getItem(this._confEmailDocsFiredSessionKey) === policyReference;

  }

  /**
   * Gets the value of the last purchased policy reference recorded in session.
   */
  public getLastPurchasedPolicyReference(): string {

    return sessionStorage.getItem(this._lastPurchasedPolicyReferenceSessionKey);

  }

  /**
   * Takes the supplied destination array and returns a combined list of all the
   * countries they contain.
   */
  public getAllCountriesFromDestinations(destinations: Destination[]): Country[] {
    let allCountries: Country[] = [];

    destinations.forEach(d => {
      allCountries = allCountries.concat(d.countries);
    });

    return allCountries;
  }

  /**
   * Takes the list of countries and returns a new country array filtered by the 
   * country Ids supplied.
   */
  public getCountriesFilteredByIds(countries: Country[], filterCountryIds: number[]): Country[] {
    const countriesFiltered: Country[] = [];

    countries.forEach(c => {
      if(filterCountryIds.includes(c.id)) countriesFiltered.push(c);
    });

    return countriesFiltered;
  }

  /**
   * Takes the country ID and returns the name using the supplied destination array.
   */
  public getCountryNameById(countryId: number, destinations: Destination[]): string {
    const allCountries = this.getAllCountriesFromDestinations(destinations);
    const countryFound = allCountries.find(({ id }) => id === countryId);

    if(countryFound !== undefined) return countryFound.name;
    return '';
  }

  /**
   * Takes an array of country IDs and converts to a formatted string of country names using the
   * supplied destination array. If IDs not found in country array, an empty string is returned.
   */
  public getCountryNamesAsStringFromDestinationArr(countryIds: number[], destinations: Destination[]): string {
    if(countryIds.length === 0) return '';
    
    const allCountries = this.getAllCountriesFromDestinations(destinations);

    return this.getCountryNamesAsStringFromCountryArr(countryIds, allCountries);
  }

  /**
   * Takes an array of country IDs and converts to a formatted string of country names using the
   * supplied country array. If IDs not found in country array, an empty string is returned.
   */
  public getCountryNamesAsStringFromCountryArr(countryIds: number[], countries: Country[]): string {
    if(countryIds.length === 0) return '';
    
    const countryNamesArray: string[] = [];

    countries.forEach(c => {
      if(countryIds.includes(c.id)) countryNamesArray.push(c.name);
    });

    return countryNamesArray.join(', ');
  }

  /**
   * Takes the supplied countries and an array of those selected. Using this information, a list of unique 
   * destinations is created (multiple countries can share the same destination) and used to determine
   * which destination has the highest tier value; the destination ID for this is returned.
   */
  public getTopTierDestinationByCountries(countries: Country[], selectedCountryIds: number[], destinations: DestinationConfigVersion[]): number {
    const selectedCountries = countries.filter(c => selectedCountryIds.includes(c.id));
    const uniqueDestinations = new Map<number, DestinationConfigVersion>();
  
    selectedCountries.forEach(c => {
      const d = destinations.find(({ destinationId }) => destinationId === c.destination.id);
      uniqueDestinations.set(c.destination.id, d);
    });
  
    let highestTierDestId = -1;
    let highestTierFound = -1;
  
    uniqueDestinations.forEach((destination, _key, _map) => {
      if(destination.tier > highestTierFound) {
        highestTierFound = destination.tier;
        highestTierDestId = destination.destinationId;
      }
    });
  
    return highestTierDestId;
  }

  /**
   * Takes the supplied countryIds and complete list of destinations, finds the countries destinations
   * and returns the value for the highest HealixRegion of the destinations.
   */
  public getTopHealixRegion(selectedCountryIds: number[], destinations: Destination[]): number {
    const uniqueDestinations = new Map<number, Destination>();

    destinations.forEach(d => {
      const destCountryIds = d.countries.map(({ id }) => id);

      for(let i = 0; i < selectedCountryIds.length; i++) {
        if(destCountryIds.includes(selectedCountryIds[i])) {
          uniqueDestinations.set(d.id, d);
          break;
        }
      }
    });

    if(uniqueDestinations.size < 1) throw new Error('getTopHealixRegion method could not match selectedCountryIds to destinations. No HealixRegion could be established.');

    const allHealixRegions = [...uniqueDestinations.values()].map(({ healixRegion }) => healixRegion);
    const sortedHealixRegions = allHealixRegions.sort((a, b) => a - b);

    return sortedHealixRegions[sortedHealixRegions.length - 1];
  }

  /**
   * Takes the given destination, duration and returns the maximum age allowable based on the 
   * supplied trip limits.
   */
  public getMaxAgeFromTripLimits(destId: number, durationDays: number, tripLimits: TripLimit[]): number {
    const tripLimit = tripLimits.find(({ destinationId }) => destinationId === destId);
    let maxAgeFound = 0;
  
    tripLimit.limits.forEach(limit => {
      if(durationDays <= limit.maxDurationDays && limit.maxAge > maxAgeFound) {
        maxAgeFound = limit.maxAge;
      }
    });
  
    return maxAgeFound;
  }

  /**
   * Takes the provided certificate and returns a value indicating whether it 
   * can be purchased in its current state.
   */
  public canPurchasePolicy(certificate: Certificate): boolean {

    // TODO: Implement relevant checks.
    // Should be called by payment component at least.

    return false;

  }

  /**
   * Takes the provided certificate and returns a value indicating whether
   * it can be edited.
   */
  public canEditPolicy(certificate: Certificate): boolean {

    return certificate.status === CertificateStatus.IncompleteQuote;
    
  }

  /**
   * Takes the provided certificate and returns a value indicating whether
   * medical cover is declined for any client.
   */
  public isMedicalDeclined(certificate: Certificate): boolean {

    for(let i = 0; i < certificate.clients.length; i++) {
      if(certificate.clients[i].screening) {
        if(this.isScreeningResultIdDeclined(certificate.clients[i].screening)) return true;
      }
    }

    return false;
    
  }

  /**
   * Takes the provided screening and returns a value indicating whether
   * it contains a resultId which should force a decline.
   */
  public isScreeningResultIdDeclined(screening: Screening): boolean {

    if(screening.screeningResultId === ScreeningResultEnum.CannotProvideMedicalCover || screening.screeningResultId === ScreeningResultEnum.CannotProvideInsurance) {
      return true;
    }

    return false;

  }

  /**
   * @deprecated Should no longer be used. After switching to FM Magenta instance
   * the excluded flag is not set after saving as it was with TIF's version.
   * Takes the provided screening and returns a value indicating whether
   * it contains any excluded conditions.
   */
  public containsExcludedConditions(screening: Screening): boolean {

    if(screening?.conditions?.length > 0) {
      const condition = screening.conditions.find(({ excluded }) => excluded === true);
      return condition !== undefined;
    }

    return false;

  }

  /**
   * Takes the incoming source and returns value indicating whether it's a Idol source.
   */
  public isMagentaSourceIdol(magentaSource: MagentaSource): boolean {
    return this._magentaSourceIdol.includes(magentaSource);
  }

  /**
   * Takes the incoming source and returns value indicating whether it's a CYTI source.
   */
  public isMagentaSourceCyti(magentaSource: MagentaSource): boolean {
    return this._magentaSourceCyti.includes(magentaSource);
  }

  /**
   * Takes the incoming source and returns value indicating whether it's a QuoteZone source.
   */
  public isMagentaSourceQuoteZone(magentaSource: MagentaSource): boolean {
    return this._magentaSourceQuoteZone.includes(magentaSource);
  }

  /**
   * Takes the incoming source and returns value indicating whether it's a aggregator source.
   */
  public isMagentaSourceAggregator(magentaSource: MagentaSource): boolean {
    return this.isMagentaSourceIdol(magentaSource) || this.isMagentaSourceCyti(magentaSource) || this.isMagentaSourceQuoteZone(magentaSource);
  }

  /**
   * Takes the provided raw SchemeCovers from Magenta and SchemeCoverAdditionals from config
   * and returns a populated array of SchemeCoverAdditionals ready for display.
   */
  public populateSchemeCoverAdditionals(schemeCoversFromMagenta: SchemeCover[],
                                        schemeCoverAdditonalsFromConfig: SchemeCoverAdditional[],
                                        schemeAdditionalFromConfig: SchemeAdditional): SchemeCoverAdditional[] {

    const maxExcessSectionId = -1;
    const coronaCoverSectionId = -2;

    // Get the max excess value.
    const maxExcessExcludeName = 'personal liability';
    let maxExcess = 0;

    schemeCoversFromMagenta.forEach(schemeCover => {
      if(schemeCover.sectionName.trim().toLowerCase() !== maxExcessExcludeName) {
        maxExcess = schemeCover.excess > maxExcess ? schemeCover.excess : maxExcess;
      }
    });

    // Set the covid cover value.
    const coronaCoverValue: string = schemeAdditionalFromConfig.coronaCoverIncluded ? 'Yes' : 'No';

    // Build the array of SchemeCoverAdditionals with values populated.
    const schemeCoverAdditionals: SchemeCoverAdditional[] = [];

    schemeCoverAdditonalsFromConfig.forEach(sca => {
      
      // Get schemeCover from Magenta version.
      const schemeCoverAdditional = new SchemeCoverAdditional();
      schemeCoverAdditional.sectionId = sca.sectionId;
      schemeCoverAdditional.nameOverride = sca.nameOverride;
      schemeCoverAdditional.descriptionHtml = sca.descriptionHtml;

      if(sca.sectionId > 0) {
        const sc = schemeCoversFromMagenta.find(({ sectionId }) => sectionId === sca.sectionId);

        if(sc !== undefined) {
          schemeCoverAdditional.sectionId = sca.sectionId;
          schemeCoverAdditional.nameOverride = sca.nameOverride ? sca.nameOverride : sc.sectionName;
          schemeCoverAdditional.limit = sc.limit;
          schemeCoverAdditional.dataValueOverride = sca.dataValueOverride !== '' ? sca.dataValueOverride : null;
        }
      } else {
        // SectionIds with a negative value don't originate from Magenta and are added via configuration
        // and have to be treated differently in order to set whatever value's needed.
        schemeCoverAdditional.sectionId = sca.sectionId;

        switch(sca.sectionId) {
          case maxExcessSectionId: {
            schemeCoverAdditional.limit = maxExcess;
            break;
          }
          case coronaCoverSectionId: {
            schemeCoverAdditional.dataValueOverride = coronaCoverValue;
            break;
          }
          default: {

            break;
          }
        }
      }

      schemeCoverAdditionals.push(schemeCoverAdditional);
    });

    return schemeCoverAdditionals;
  }

  /**
   * Gets country list for specified scheme group.
   */
  public getCountriesBySchemeGroup(schemeGroupId: number): Observable<Country[]> {

    if(this._schemeGroupCountryCache.has(schemeGroupId)) {
      return of(this._schemeGroupCountryCache.get(schemeGroupId));
    }

    const url = `${this._appConfigService.quoteApiBaseUrl}/Codes/GetCountriesBySchemeGroup/${schemeGroupId}`;

    return this._http.get<Country[]>(url)
             .pipe(
               tap(countries => {
                 this._schemeGroupCountryCache.set(schemeGroupId, countries);
               })
             );

  }

  /**
   * Gets country list for specified scheme group and filters returned countries to only those included in the supplied countryIds array.
   */
  public getCountriesBySchemeGroupFiltered(schemeGroupId: number, countryIds: number[]): Observable<Country[]> {

    return this.getCountriesBySchemeGroup(schemeGroupId)
             .pipe(
                map(countries => {
                  return countries.filter(c => countryIds.includes(c.id))
                })
             );

  }

  /**
   * Gets a certificate by certificateId.
   */
  public getPolicy(certificateId: number): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetPolicy/${certificateId}`;

    return this._http.get<Certificate>(url);

  }

  /**
   * Gets a certificate by external reference. Used to load CYTI certificates.
   */
  public getPolicyByExternalRef(byExternalRefRequest: ByExternalRefRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetPolicyByExteralRef`;

    return this._http.post<Certificate>(url, byExternalRefRequest);

  }

  /**
   * Gets a Idol certificate by quote reference. Used for retrieving quotes created on Idol platform.
   */
  public importIdolCreatePolicy(importIdolCreatePolicyRequest: ImportIdolCreatePolicyRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/ImportIdolCreatePolicy`;

    return this._http.post<Certificate>(url, importIdolCreatePolicyRequest);

  }

  /**
   * Gets a certificate by quote number and email. Used for retrieving existing quotes.
   */
  public getPolicyByQuoteNumberAndEmail(byQuoteNumberAndEmailRequest: ByQuoteNumberAndEmailRequest): Observable<ByQuoteNumberAndEmailResponse> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetPolicyByQuoteNumberAndEmail`;

    return this._http.post<ByQuoteNumberAndEmailResponse>(url, byQuoteNumberAndEmailRequest);

  }

  /**
   * Gets a live certificate that's been recently purchased. Used for retrieving a live policy after 3DS auth.
   */
  public getPolicyByRecentlyPurchased(byRecentlyPurchasedRequest: ByRecentlyPurchasedRequest): Observable<ByRecentlyPurchasedResponse> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetPolicyRecentlyPurchased`;

    return this._http.post<ByRecentlyPurchasedResponse>(url, byRecentlyPurchasedRequest);

  }

  /**
   * Gets trigger questions for specified agent.
   */
  public getTriggerQuestions(agentId: number): Observable<TriggerQuestion[]> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetTriggerQuestions/${agentId}`;

    return this._http.get<TriggerQuestion[]>(url);

  }

  /**
   * Gets an array of quoted schemes based on request criteria.
   */
  public getSchemes(getSchemesRequest: GetSchemesRequest): Observable<GetSchemesResponse> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/GetSchemes`;

    return this._http.post<GetSchemesResponse>(url, getSchemesRequest);

  }

  /**
   * Sets the certificate published by this service and stores to local session storage.
   * Client will set this after each successful save.
   */
  public setCertificate(certificate: Certificate): void {

    sessionStorage.setItem(this._certificateSessionKey, JSON.stringify(certificate));

    this._certificateSource.next(certificate);

  }

  /**
   * Clears the certificate published by this service and removes related session data.
   */
  public clearCertificate(): void {

    sessionStorage.removeItem(this._certificateSessionKey);
    sessionStorage.removeItem(this._isSchemeUserSelectedSessionKey);
    sessionStorage.removeItem(this._activeScreeningSessionKey);

    this._certificateSource.next(null);

  }

  /**
   * Clears session data not required after successful purchase.
   */
  public clearAfterConfirmationSessionData(): void {

    sessionStorage.removeItem(this._isSchemeUserSelectedSessionKey);
    sessionStorage.removeItem(this._activeScreeningSessionKey);

  }

  /**
   * Creates a new policy with the minimum data required.
   */
  public savePolicyCreateQuoteNumber(createQuoteNumberRequest: CreateQuoteNumberRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyCreateQuoteNumber`;

    return this._http.put<Certificate>(url, createQuoteNumberRequest);

  }

  /**
   * Saves an incomplete policy.
   */
  public savePolicyIncompleteQuote(incompleteQuoteRequest: IncompleteQuoteRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyIncompleteQuote`;

    return this._http.patch<Certificate>(url, incompleteQuoteRequest);

  }

  /**
   * Saves an incomplete policy, but updates only the personal details for the lead traveller of the certificate.
   */
  public savePolicyIncompleteQuotePersonalDetails(incompleteQuotePersonalDetailsRequest: IncompleteQuotePersonalDetailsRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyIncompleteQuotePersonalDetails`;

    return this._http.patch<Certificate>(url, incompleteQuotePersonalDetailsRequest);

  }

  /**
   * Saves an incomplete policy, but updates only the specified client's Verisk RRT3 medical screening data.
   */
  public savePolicyIncompleteQuoteClientRrtScreening(request: IncompleteQuoteClientRrtScreeningRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyIncompleteQuoteClientRrtScreening`;

    return this._http.patch<Certificate>(url, request);

  }

  /**
   * Generates quote.
   */
  public savePolicyGenerateQuote(generateQuoteRequest: GenerateQuoteRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyGenerateQuote`;

    return this._http.patch<Certificate>(url, generateQuoteRequest);

  }

  /**
   * Generates a live policy.
   */
  public savePolicyGeneratePolicy(generatePolicyRequest: GeneratePolicyRequest): Observable<Certificate> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SavePolicyGeneratePolicy`;

    return this._http.patch<Certificate>(url, generatePolicyRequest);

  }

  /**
   * Saves trigger question answers against the specified policy.
   */
  public saveTriggerAnswers(triggerAnswersRequest: TriggerAnswersRequest): Observable<void> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Quote/SaveTriggerAnswers`;

    return this._http.put<void>(url, triggerAnswersRequest);

  }

  /**
   * Emails quote documents to customer.
   */
  public emailDocumentsForQuote(emailDocumentsRequest: EmailDocumentsRequest): Observable<EmailDocumentsResponse> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Document/EmailDocumentsForQuote`;

    return this._http.post<EmailDocumentsResponse>(url, emailDocumentsRequest);

  }

  /**
   * Emails live policy documents to customer.
   */
  public emailDocumentsForLivePolicy(emailDocumentsRequest: EmailDocumentsRequest): Observable<EmailDocumentsResponse> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Document/EmailDocumentsForLivePolicy`;

    return this._http.post<EmailDocumentsResponse>(url, emailDocumentsRequest);

  }

  /**
   * Gets a list of document details related to live certificate.
   * If an error occurs, an empty file array is returned.
   */
  public getDocumentsForLivePolicy(getDocumentsRequest: GetDocumentsRequest): Observable<File[]> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Document/GetDocumentsForLivePolicy`;

    return this._http.post<File[]>(url, getDocumentsRequest)
      .pipe(
        catchError(err => {
          console.error(`getDocumentsForLivePolicy call failed. Details: ${err}`);
          return of([] as File[]);
        })
      );

  }

}
